import { render, staticRenderFns } from "./configure-provider-data-fields.vue?vue&type=template&id=714d4156&"
import script from "./configure-provider-data-fields.vue?vue&type=script&lang=js&"
export * from "./configure-provider-data-fields.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports